.popover {
  border-color: rgba(115, 107, 103, .15);
  border-radius: 0;
  .popover-body {
    padding: 1rem;
    box-shadow: 0 4px 16px 0 rgba($gray-800, .15);

    .popover-heading {
      line-height: 1.25rem;
    }
  }
}
