.power-link:not([href]):not([tabindex]) {
  color: $primary;
  text-decoration: none;

  @include hover-focus() {
    color: $primary-dark;
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}
