.company-profile-link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.company-profile-list-item:hover {
  .company-profile-list-title {
    text-decoration: underline;
  }
}

.cities-letters {

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
  }

  .city-letter-scope {
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }

    .city-name-scope {
      line-height: 1.5rem;

      .city-companies-count {
        line-height: 1.25rem;
      }
    }
  }
}
