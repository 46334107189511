.latest-article {
  @include media-breakpoint-up(lg) {
    .custom-flex-container {
      display: flex;
    }
    .left-column {
      flex: 0 0 60%;
    }
    .right-column {
      flex: 0 0 40%;
    }
  }
}
