.how-it-works {
  .background-dots {
    background-position: 0 $spacer;
    background-size: 268px 343px;
  }

  @include media-breakpoint-down(md) {
    .background-dots {
      background-position: 0 6px;
      background-size: 147px 333px;
    }
  }

  .how-to-request-quotes-container {
    background-color: #f2f7fc;
    @include media-breakpoint-down(md) {
      background: linear-gradient(to bottom, #fff 0%, #fff 76px, #f2f7fc 76px, #f2f7fc 100%);
    }

    .video {
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    .circle.circle-hiw {
      width: $circle-hiw-width;
      height: $circle-hiw-height;
      font-size: $font-size-sm;
    }
  }
}
