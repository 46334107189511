@import "utilities/gray_background";
@import "utilities/loading";
@import "utilities/spacing";

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}

.content-visibility {
  content-visibility: auto;
}

// stylelint-disable declaration-no-important
.overflow-wrapper {
  padding: 0 !important;
}
.overflow-wrapper > * {
  padding-right: 15px;
  padding-left: 15px;
  .overflow-auto {
    padding-left: 15px;
  }
}

.overflow-element:last-of-type {
  margin-right: 15px;
}
