// this styles is a css adjustments for registration flow to make it work with custom-move-scss styleguide
// until we migrate registration flow to templates to bootstrap v4
.move-company-registration {
  .hide-placeholder::placeholder {
    color: $gray-500;
  }
  .sd-form-group,
  .form-group {
    .error {
      margin-top: .5 * $spacer;
      font-size: $font-size-sm;
    }
    .placeholder {
      display: none;
    }
  }
  .regions {
    .card {
      &.hover {
        border-color: $secondary;
      }
    }
  }
  .sd-control-label {
    small {
      margin-left: $spacer * .5;
      font-size: $font-size-sm;
      color: $gray-600;
      text-transform: capitalize;
      .parentheses {
        display: none;
      }
    }
  }
}

.info-panel-trigger {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.2;
  color: $white;
  text-align: center;
  background-color: $secondary;
  border: 1px solid $secondary;
  border-radius: 50%;
  &.active,
  &[aria-describedby]{
    color: $secondary;
    background-color: $white;
  }
  &:hover {
    cursor: pointer;
  }
}
