.radio-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 16px;
  overflow: hidden;
  font-family: $font-family-sofia-pro;
  cursor: pointer;
  border: 2px solid $gray-300;
  border-radius: $border-radius;

  .title {
    font-weight: $font-weight-bold;
  }

  p {
    @extend .smaller;

    color: $gray-700;
  }

  .footer {
    position: absolute;
    right: 4px;
    bottom: 0;
    left: 4px;
    padding-top: .125rem;
    padding-left: 12px;
    color: $gray-700;
    background-color: $gray-300;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    @include media-breakpoint-up(lg) {
      right: 0;
      bottom: -7px;
      left: 0;
    }
  }

  &.selected,
  &:hover {
    border-color: $primary;

    .footer {
      color: $white;
      background-color: $primary;
    }
  }

  &.selected {
    .title {
      color: $gray-800;
    }

    p {
      font-weight: $font-weight-bold;
    }
  }
}
