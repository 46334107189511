.cities-list-wrapper {
  overflow-x: auto;

  .row > .col-4 {
    min-width: 300px;
  }

  @include media-breakpoint-down(sm) {
    .row > .col-4 {
      min-width: 240px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.city-element {

  &-image {
    width: 72px;
    height: 58px;
    @include media-breakpoint-down(md) {
      width: 56px;
      height: 50px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &-description {
    border: $border-width solid $border-color;
    border-left: 0;

    &-name {
      margin-right: .375rem;
      line-height: 1.5rem;
    }

    &-companies-count {
      line-height: 1rem;
      color: $gray-600;
    }
  }

  &:hover {
    .city-element-description-name {
      text-decoration: underline;
    }
  }
}
