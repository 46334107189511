.tablepress {
  margin-bottom: $spacer * 1.5;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  border: 1px solid $gray-300;

  tfoot th,
  thead th {
    background-color: $gray-300;
    border-color: $gray-300;
  }

  .odd td {
    background-color: $gray-100;
  }

  .row-hover tr:hover td {
    background-color: $gray-200;
  }

  tr {
    border-bottom: 1px solid $gray-300;
  }

  th,
  td {
    padding: $spacer * .5 $spacer * 1.12;
  }

  @include media-breakpoint-down(xs) {
    th,
    td {
      padding: $spacer * .5;
    }
  }
}

.responsive-table {
  max-width: 100%;
  overflow-x: auto;
}
