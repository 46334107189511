.company-profile-badge {
  padding: 0 6px;
  user-select: none;
}

.partner-badge {
  &.active {
    background-color: $warning-bg;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      background-color: $warning-bg;
    }
  }
}
