.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-footer {
  z-index: 5;
  width: 100%;
  padding: 1rem 0;
  margin-top: 3rem;
  background-color: $white;
  box-shadow: $all-border-shadow;
}

html,
body {
  height: 100%;
  font-size: $font-size-base;
  background-color: $gray-100;
}

.loading-mask,
.login-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: $white;
  opacity: .95;

  .content {
    position: relative;
    top: 50%;
    text-align: center;

    h1 {
      color: $text-color;
    }

    img {
      margin-bottom: 8px;
    }
  }
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.referral-header {
  height: 18rem;
  margin-top: -.45rem;
  background-color: $accent-lighter;

  @include media-breakpoint-down(sm) {
    height: 32rem;
  }
}

.referral-body {
  margin-top: -292px;

  @include media-breakpoint-down(sm) {
    margin-top: -33rem;
  }
}

.indent-level-1 {
  margin-left: 0;
}

@for $index from 2 through 6 {
  .indent-level-#{$index} {
    margin-left: 1.25rem * ($index - 2);
  }
}

.angular-google-map-container {
  height: 400px;
}

// temp notice style, change this when notice design is provided
.notice-container {
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  z-index: 1200;

  .notice {
    position: relative;
    display: inline-block;
    max-width: 90%;
    padding: 1.25em 1.5em;
    margin: 0 auto;
    color: $primary;
    background-color: $primary-light;
    border-color: $primary-light;
    border-radius: $border-radius;
  }

  @include media-breakpoint-up(sm) {
    .notice {
      padding: 1.25rem 3.5rem;
    }
  }
}

.scrollable {
  max-height: 10rem;
  overflow-y: auto;
}

.box-shadow {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .16);
}

.box-shadow-bottom {
  box-shadow: $box-shadow-bottom;
}

.box-shadow-bottom-right-lg {
  box-shadow: 8px 8px 0 0 rgba(20, 48, 102, .03);
}

.d-sm-width-auto {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .prevent-vertical-growth#{$infix} {
      margin-top: -.5rem;
      margin-bottom: -.5rem;
    }
  }
}

.col-grow {
  flex: 1 1 auto;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .w-50#{$infix} {
      width: 50%;
    }
  }
}
