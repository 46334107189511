/**
 * @license
 * MyFonts Webfont Build ID 3765930, 2019-05-24T08:32:43-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: QuestaSans-Regular by The Questa Project
 * URL: https://www.myfonts.com/fonts/questa/questa-sans/regular/
 * Licensed pageviews: Unlimited
 *
 * Webfont: QuestaSans-Bold by The Questa Project
 * URL: https://www.myfonts.com/fonts/questa/questa-sans/bold/
 * Licensed pageviews: 1,000,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3765930
 * Webfonts copyright: &#x00A9;2013, Jos Buivenga &amp; Martin Majoor
 *
 * © 2019 MyFonts Inc
*/

@font-face {
  font-family: QuestaSans;
  font-style: normal;
  font-weight: $font-weight-normal;
  src: url("../fonts/questa-sans/3AD317_0_0.eot");
  src: url("../fonts/questa-sans/3AD317_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/questa-sans/3AD317_0_0.woff2") format("woff2"), url("../fonts/questa-sans/3AD317_0_0.woff") format("woff"), url("../fonts/questa-sans/3AD317_0_0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: QuestaSans;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url("../fonts/questa-sans/3AD317_1_0.eot");
  src: url("../fonts/questa-sans/3AD317_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/questa-sans/3AD317_1_0.woff2") format("woff2"), url("../fonts/questa-sans/3AD317_1_0.woff") format("woff"), url("../fonts/questa-sans/3AD317_1_0.ttf") format("truetype");
  font-display: swap;
}
