.panel {
  position: relative;
  padding: $panel-padding-small;
  background-color: $white;
  border-radius: $panel-border-radius;

  @include media-breakpoint-up(md) {
    padding: $panel-padding-medium;
  }

  @include media-breakpoint-up(lg) {
    padding: $panel-padding-large;
  }

  &.panel-shadowed {
    margin-bottom: $box-shadow-lg-size; //To make the box shadow always visible
    box-shadow: $box-shadow-lg;
  }

  &.panel-bordered {
    border: $border-width solid $border-color;
  }

  &.panel-top {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.panel-middle {
    border-top: 0;
    border-radius: 0;
  }

  &.panel-bottom {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

// stylelint-disable selector-no-qualifying-type
// An anchor has to behave different when being a clickable panel
a.panel {
  display: flex;
  color: $text-color;

  &:hover {
    color: $text-color;
    text-decoration: none;
  }

  &:active {
    font-weight: $font-weight-normal;
  }

  &.panel-bordered:active {
    border-color: $gray-400;
  }
  &.panel-shadowed:active {
    box-shadow: $box-shadow-lg-size $box-shadow-lg-size 0 0 rgba(20, 48, 102, .05);
  }
}
// stylelint-enable selector-no-qualifying-type


// stylelint-disable declaration-no-important
// I don't know why this needs to be important
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .panel#{$infix}-small {
      padding: $panel-padding-small !important;
    }
    .panel#{$infix}-medium {
      padding: $panel-padding-medium !important;
    }
    .panel#{$infix}-large {
      padding: $panel-padding-large !important;
    }
  }
}
//stylelint-enable declaration-no-important

// stylelint-disable declaration-no-important
// I don't know why this needs to be important
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $direction in $directions {
      .border-#{$direction}#{$infix} {
        border-#{$direction}: $border-width solid $border-color !important;
      }

      .border-#{$direction}#{$infix}-0 {
        border-#{$direction}: 0 !important;
      }
    }
  }
}
//stylelint-enable declaration-no-important

.panel-warning {
  padding: 20px;
  font-size: $font-size-sm;
  background-color: $gray-100;
}

.sd-dropdown-item-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 16px;
  height: 16px;
  $image-url: get-arrow-dropdown-icon($secondary, bottom);
  background: $image-url;
  background-repeat: no-repeat;
}

.sd-dropdown-item-icon.top-arrow {
  transform: rotate(180deg);
}

.sd-btn-no-shadow:focus {
  box-shadow: none;
}

.sd-text-normal {
  white-space: normal;
}

.sd-cursor-pointer {
  cursor: pointer;
}

.sd-hovered-panel {
  border-radius: 3px;

  &:hover {
    border-color: #2b65d9;
  }
}

.sd-faq-panel {
  &[aria-expanded="true"] {
    background-color: $gray-100;
  }
}
