.background-dots{
  background-image: url("../images/move/home/how-it-works/background.svg");
  background-repeat: no-repeat;
}
@include media-breakpoint-down(md) {
  .background-dots{
    background-image: url("../images/move/home/how-it-works/background-mobile.svg");
  }
}

//stylelint-disable declaration-no-important
//Because bootstrap creates the same class with the dark version
.bg-primary {
  background-color: $primary-bg !important;
}

.bg-secondary {
  background-color: $secondary-bg !important;
}

.bg-secondary-darker {
  background-color: $secondary !important;
}

.bg-secondary-dark {
  background-color: $secondary-dark !important;
}

//stylelint-enable declaration-no-important

.bg-white {
  background-color: $white;
}

.bg-grey {
  background-color: $gray-300;
}

.bg-grey-light {
  background-color: $gray-100;
}
