h1,
.h1 {
  margin-top: 2 * $spacer;
  margin-bottom: 1 * $spacer;
  @include media-breakpoint-up(md) {
    margin-top: 3.5 * $spacer;
    margin-bottom: 2 * $spacer;
  }
}
h2,
.h2 {
  margin-top: 1.75 * $spacer;
  margin-bottom: .75 * $spacer;
  @include media-breakpoint-up(md) {
    margin-top: 2.5 * $spacer;
    margin-bottom: 1.5 * $spacer;
  }
}
h3,
.h3 {
  margin-top: 1.5 * $spacer;
  margin-bottom: .5 * $spacer;
  @include media-breakpoint-up(md) {
    margin-top: 2 * $spacer;
    margin-bottom: .75 * $spacer;
  }
}
h4,
.h4 {
  margin-top: 1 * $spacer;
  margin-bottom: .25 * $spacer;
  @include media-breakpoint-up(md) {
    margin-top: 1.5 * $spacer;
    margin-bottom: .5 * $spacer;
  }
}
h5,
.h5 {
  margin-top: 1 * $spacer;
  margin-bottom: .25 * $spacer;
}
