.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-100 {
  flex-basis: 100%;
}
.justify-content-space-between {
  justify-content: space-between;
}

.horizontal-flex-scroll-container {
  display: block;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
  overflow: hidden;

  .horizontal-flex-scroll {
    display: flex;
    gap: .5rem;
    width: fit-content;
    max-width: 100%;
    padding: 0 1rem;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch;

    > * {
      scroll-snap-align: center;
      scroll-snap-stop: always;
      flex: 0 0 auto;
      margin: auto;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

