// stylelint-disable declaration-no-important

//TODO - This will be improved in #38553 with a loading screen
.page-loading {
  display: none;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
