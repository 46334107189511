// The Angular 6 datepicker
// stylelint-disable

.btn-light:hover {
  background-color: $primary;

  &.text-muted {
    color: $white !important;
  }
}


/**
The styles below are required to style the ui-bootstrap datepicker directive
This directive adds bootstrap 3 classes, so we add these custom styles to match the design with our styleguide
These styles will support both ui-bootstrap versions 0.x and 2.x
 */
.uib-datepicker-popup * {
  outline: none;
}

.uib-datepicker-popup,
div[datepicker] {
  overflow: hidden;
  border: $table-border-width solid $table-border-color;
  box-shadow: 0 0 8px 0 $table-border-color;

  .uib-daypicker {
    background-color: $gray-100;
  }

  &.dropdown-menu {
    min-width: 0;
  }

  table {
    width: 100%;
    font-size: $font-size-sm;
  }

  tbody::after {
    display: inline-block;
    width: 100%;
    height: 20px;
  }

  .active {
    background-color: $gray-200;
  }

  tr {
    & > td:first-child {
      font-size: $font-size-sm;
      color: $gray-700;
      background-color: $gray-100;
    }

    small {
      font-size: 100%;
    }

    em {
      font-style: normal;
    }
  }

  thead * {
    padding: 0;
    color: $text-color;
    border: none;
  }
  thead {
    tr:first-child {
      background-color: $white;
      border-bottom: 1.5px solid $gray-300;
    }
    tr:last-child {
      th {
        padding-top: .375rem;
        padding-bottom: .375rem;
      }
    }
  }

  .btn {
    padding: .375rem .45rem;
    &:not(.active) {
      background-color: $white;
    }
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: $text-color;

    &.uib-title,
    &[role="heading"] {
      padding: .75rem 0 .75rem 0;
      border: none;
    }

    &.uib-left,
    &.pull-left {
      transform: rotate(180deg);
    }

    .text-muted {
      color: $gray-500;
    }
    &:not(:disabled):not(.uib-title):hover {
      color: $white;
      background-color: $primary;

      .text-muted {
        color: $white !important;
      }
    }
    span {
      background-color: transparent;
    }
    &.uib-left,
    &.pull-left,
    &.uib-right,
    &.pull-right {
      width: 30px;
      height: 23px;
      background-image: get-arrow-dropdown-icon($primary, right);
      background-repeat: no-repeat;
      background-position-x: .8rem;
      background-position-y: .35rem;
      border: none;

      &:not(:disabled):hover {
        color: $white !important;
        background-color: transparent;
      }

      &:active {
        background-image: get-arrow-dropdown-icon($primary, right);
      }
    }

    &.uib-left,
    &.pull-left {
      margin-left: .25rem
    }
  }

  .btn.disabled,
  .btn:disabled {
    cursor: not-allowed;
    background-color: #e6e6e6;
  }

  .btn-info:not(:disabled):not(.disabled).active {
    color: $white;
    background-color: $primary;
  }
}
