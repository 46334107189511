@font-face {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: $font-weight-normal;
  src: url("../fonts/hk-grotesk/hkgrotesk-regular.eot");
  src: url("../fonts/hk-grotesk/hkgrotesk-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk/hkgrotesk-regular.woff2") format("woff2"), url("../fonts/hk-grotesk/hkgrotesk-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url("../fonts/hk-grotesk/hkgrotesk-bold.eot");
  src: url("../fonts/hk-grotesk/hkgrotesk-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/hk-grotesk/hkgrotesk-bold.woff2") format("woff2"), url("../fonts/hk-grotesk/hkgrotesk-bold.woff") format("woff"), url("../fonts/hk-grotesk/hkgrotesk-bold.ttf") format("truetype");
  font-display: swap;
}
