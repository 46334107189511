.nav-tabs .nav-link {
  padding: calc(#{$btn-padding-x} - .4375rem) $btn-padding-x;
  font-weight: $font-weight-bold;
  line-height: $input-btn-line-height;
  color: $secondary;
  background-color: $secondary-bg;
  border-bottom: 0;

  &.active {
    background-color: $white;
  }

  &:not(:disabled):not(.disabled):hover {
    color: $gray-700;
  }
}
