.upper {
  @extend .text-uppercase;
}

// stylelint-disable declaration-no-important

.font-hk-grotesk {
  font-family: $font-family-hk-grotesk;
}

//
// Headings
//
h1,
.h1 {
  margin-bottom: 1rem;
  font-variant-numeric: lining-nums;
  line-height: $h1-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
    font-size: $h1-font-size-desktop;
    line-height: $h1-line-height-desktop;
  }
}

h2,
.h2 {
  margin-bottom: .75rem;
  font-variant-numeric: lining-nums;
  line-height: $h2-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
    font-size: $h2-font-size-desktop;
    line-height: $h2-line-height-desktop;
  }
}

h3,
.h3 {
  margin-bottom: .5rem;
  font-variant-numeric: lining-nums;
  line-height: $h3-line-height;

  @include media-breakpoint-up(md) {
    margin-bottom: .75rem;
    font-size: $h3-font-size-desktop;
    line-height: $h3-line-height-desktop;
  }
}

h4,
.h4 {
  margin-bottom: .25rem;
  font-variant-numeric: lining-nums;
  line-height: $h4-line-height;
  color: $gray-700;

  @include media-breakpoint-up(md) {
    margin-bottom: .5rem;
    font-size: $h4-font-size-desktop;
    line-height: $h4-line-height-desktop;
  }
}

h5,
.h5 {
  margin-bottom: .25rem;
  font-variant-numeric: lining-nums;
  line-height: $h5-line-height;
  color: $gray-700;

  @include media-breakpoint-up(md) {
    font-size: $h5-font-size-desktop;
    line-height: $h5-line-height-desktop;
  }
}

h6,
.h6 {
  margin-bottom: 0;
  font-variant-numeric: lining-nums;
  line-height: $h6-line-height;

  @include media-breakpoint-up(md) {
    font-size: $h6-font-size-desktop;
    line-height: $h6-line-height-desktop;
  }
}

//
// Links
//

a {
  font-family: $link-font-family;
  color: $link-color;
  text-decoration: $link-decoration;
  cursor: pointer;

  &:hover {
    color: $link-color;
    text-decoration: $link-hover-decoration;
  }
}

p > a {
  font-family: $link-font-family;
}

//
// Paragraphs
//

p {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.p-small {
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

.p-large {
  font-size: $font-size-lg;
  line-height: $line-height-lg;
}
@include media-breakpoint-up(md) {
  .p-lg-large {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
  }
}

@include media-breakpoint-up(lg) {
  .p-lg-base {
    font-size: $font-size-base !important;
    line-height: $line-height-base !important;
  }
}

.p-x-large {
  font-size: $font-size-lg;
  line-height: $line-height-base;

  @include media-breakpoint-up(md) {
    font-size: $font-size-x-lg;
    line-height: $line-height-x-lg;
  }
}

.pre-title {
  font-size: $font-size-pre-title;
  line-height: $pre-title-line-height;
  color: $secondary-dark;

  @include media-breakpoint-up(md) {
    font-size: $font-size-lg-pre-title;
    line-height: $pre-title-lg-line-height;
  }
}

.smaller {
  font-size: $font-size-sm;
  line-height: 1.25rem;
}

.line-height-xs {
  line-height: $line-height-xs;
}

.line-height-sm {
  line-height: $line-height-sm;
}

// stylelint-disable-next-line selector-class-pattern
.font-size-0_875 {
  font-size: .875rem;
}

.font-size-md {
  font-size: $font-size-base !important;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-xs {
  font-size: $font-size-xs;
}

.lead {
  font-size: $font-size-lg;
  line-height: $line-height-base;
}

.text-lg {
  font-size: 2.875rem;
}

.text-gray-dark {
  color: $gray-800;
}

.text-gray {
  color: $gray-700;
}

.text-gray-light {
  color: $gray-600;
}

.text-gray-lighter {
  color: $gray-500;
}

.text-empty-state {
  font-style: italic;
  color: $gray-500;
}

.text-primary-dark {
  color: $primary-dark;
}

.help-text{
  line-height: $line-height-help-text;
}

.text-secondary-dark {
  color: $secondary-dark;
}

.label {
  font-weight: $font-weight-bold;
  color: $gray-700;
}

.text-outstanding {
  font-size: 17px;
  font-weight: $font-weight-bold;
}

// Copied the a styles as if we extend it, there is an overwriting rule for no href or tabindex links and it removes some styles
.link-secondary {
  color: $link-secondary-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-secondary-color;
    text-decoration: $link-hover-decoration;
  }

  &.text-decoration-underline {
    text-decoration: underline;
  }

  &.text-decoration-none:hover {
    text-decoration: none;
  }

  &.bold {
    font-weight: $font-weight-bold;
  }

  &.large {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
  }

  &.small {
    font-size: $font-size-sm;
    line-height: $line-height-help-text;
  }
}

.link-grey {
  color: $link-grey-color;

  &:hover {
    color: $link-grey-color;
  }
}

.link-grey-light {
  color: $link-grey-color-light;

  &:hover {
    color: $link-grey-color-light;
  }
}

.word-break {
  word-break: break-word;
}

.no-word-break {
  word-break: normal;
}

.text-decoration-none:hover {
  text-decoration: none;
}
