.card {
  border: 1px solid $gray-300;
  border-radius: 0;
  box-shadow: none;
  &.card-rounded {
    border-radius: $panel-rounded;
  }
  &.hint {
    border-width: 0;
    border-radius: 5px;
  }
}

.card-header {
  padding: 32px 32px 0;
  border: 0;
  box-shadow: none;
  .title {
    font-family: $card-title-font-family;
    color: $gray-700;
  }
}

.card-body {
  padding: 0;

  .container {
    label {
      font-family: $font-family-questa-sans;
      color: $gray-700;
    }
  }
}

.card-footer {
  padding-top: 1rem;
  @include media-breakpoint-up(lg) {
    padding-top: 1.5rem;
  }
}
