.navbar {
  z-index: 1;
  line-height: 1.33;
  color: $gray-700;
  background-color: $white;
  box-shadow: $all-border-shadow;

  .container {
    padding: 0 15px;
  }

  .navbar-brand {
    line-height: 1;
  }

  &.navbar-border-bottom {
    border-bottom: solid 1px rgba(115, 107, 103, .15);
  }

}

@include media-breakpoint-up(lg) {
  .navbar-nav:not(.navbar-open-on-click) {
    .dropdown {
      > .dropdown-menu {
        display: none;
      }
    }
    .dropdown:hover {
      > .dropdown-menu {
        display: block;
      }
    }
  }
  .navbar-open-on-click {
    .nav-item {
      &.show {
        .dropdown-toggle {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.navbar-toggler {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 17px;
  font-weight: $font-weight-bold;
  border: 0;

  &.open {
    color: $secondary;

    .navbar-toggler-icon {
      background-image: get-menu-icon($secondary);
    }
  }

  .header-seo-hidden-menu {
    @media only screen and (max-width: 360px) {
      display: none;
    }
  }

  .navbar-toggler-icon {
    width: $navbar-light-toggler-icon-width;
    height: $navbar-light-toggler-icon-height;
    margin-left: .25rem;
    background-image: get-menu-icon($text-color);
  }
}

.nav-item {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  color: $gray-700;
  @include media-breakpoint-up(lg) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .nav-link {
    padding: 0;
    font-weight: $font-weight-bold;
    color: $gray-700;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 1.5rem;
  }

  &:last-child {
    margin-right: 0;
  }

  .dropdown-toggle {
    &::after {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-top: 3px;
      vertical-align: inherit;
      content: "";
      background-image: get-arrow-dropdown-icon($gray-700, bottom);
      border: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      .nav-link {
        // stylelint-disable declaration-no-important
        // Important is needed here because the bootstrap reboot selector for links without href is too strong
        color: $secondary !important;
        // stylelint-enable declaration-no-important
      }

      .dropdown-toggle::after {
        background-image: get-arrow-dropdown-icon($secondary, bottom);
      }
    }
  }

  @media (hover: none) {
    &.dropdown.show {
      .nav-link {
        color: $secondary;
      }

      .dropdown-toggle::after {
        background-image: get-arrow-dropdown-icon($secondary, bottom);
      }
    }
  }
}

.dropdown-menu {
  padding: .75rem 0;
  margin-top: 1rem;
  border-radius: 0;

  .dropdown-item {
    padding: .5rem 1.5rem;

    &:active {
      color: $secondary;
      background-color: $secondary-bg;
    }
  }

  .dropdown-divider {
    margin: .5rem $dropdown-item-padding-x .75rem;
    border-top: 1px solid $gray-300;

    @include media-breakpoint-up(lg) {
      margin: .5rem $dropdown-item-padding-x;
    }
  }
}

@include media-breakpoint-up(lg) {
  .dropdown-menu {
    position: relative;
    margin-top: -.5rem;
    margin-bottom: 0;
    margin-left: -1.5rem;
    background: #fff;
    border: solid 1px rgba(115, 107, 103, .15);
    box-shadow: 0 4px 16px 0 rgba(26, 24, 23, .15);

    &::after,
    &::before {
      position: absolute;
      bottom: 100%;
      left: 3rem;
      width: 0;
      height: 0;
      pointer-events: none;
      content: " ";
      border: solid transparent;
    }

    &::after {
      margin-left: -12px;
      border-color: transparent;
      border-width: 12px;
      border-bottom-color: #fff;
    }

    &::before {
      margin-left: -13px;
      border-color: transparent;
      border-width: 13px;
      border-bottom-color: $gray-300;
    }

    .dropdown-item {
      &:hover {
        color: $secondary;
        background-color: $secondary-bg;
      }
    }
  }
}
