// stylelint-disable declaration-no-important
.bg-accent-light {
  background-color: $accent-light !important;
}

.bg-accent-lighter {
  background-color: $accent-lighter !important;
}

.color-accent {
  color: $accent !important;
}

.color-accent-light {
  color: $accent-light !important;
}

.color-accent-lighter {
  color: $accent-lighter !important;
}

.bg-primary-light {
  background-color: $primary-light !important;
}

.bg-primary-lighter {
  background-color: $primary-lighter !important;
}

.color-white {
  color: $white !important;
}
