.hero-b2b-image {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(to right, rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
  }

  height: $b2b-hero-height;
  margin-right: -15px;
  margin-left: -15px;

  @media (min-width: 1440px) {
    margin-right: $b2b-hero-image-margin-x;
    margin-left: $b2b-hero-image-margin-x;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* or object-fit: contain; */
  }
}

.hero-b2b-body {
  margin-top: - ($b2b-hero-height - $b2b-hero-margin-y);

  .hero-form-container {
    @include media-breakpoint-up(md) {
      max-width: $b2b-hero-form-width-desktop;
    }
  }

  .panel {
    @include media-breakpoint-up(md) {
      padding: 3 * $spacer;
    }
  }

  .form-control {
    height: $hero-form-control-height-md;
  }

  .hero-subtitle {
    max-width: $b2b-hero-subtitle-max-width;
    @include media-breakpoint-up(md) {
      font-size: $font-size-hero;
      line-height: $line-height-lg;
    }
    font-size: $font-size-lg;
    font-weight: $font-weight-normal;
    line-height: $line-height-lg;
    text-shadow: 0 0 16px rgba(0, 0, 0, .6);
  }

  .hero-title {
    max-width: $b2b-hero-title-width-desktop;
    font-family: $font-family-questa-sans;
    font-size: 2.375rem;
    line-height: 2.75rem;
    text-shadow: 0 0 16px rgba(0, 0, 0, .6);

    @include media-breakpoint-up(md) {
      margin-bottom: 18px;
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}

