.navigation-list-toggle {
  @include arrow("after", $secondary, bottom);
  display: block;
  margin-top: $spacer;
  color: $secondary;
  &:not([href]):not([tabindex]) {
    color: $secondary;
  }
  &:not([href]):not([tabindex]):focus,
  &:not([href]):not([tabindex]):hover {
    color: $secondary;
  }
  &:active{
    font-weight: $font-weight-normal;
  }
  &::after{
    margin-left: $btn-link-before-arrow-margin;
  }
}

.sd-toggled + .navigation-list-toggle {
  @include arrow("after", $secondary, bottom);
  @include arrow-rotate("after", 180deg);
}
