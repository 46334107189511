.areas-grid-list {
  column-count: 2;
  column-gap: 1rem;

  @include media-breakpoint-up(lg) {
    column-count: 3;
  }
}

.dynamic-directory-modal {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  }

  .modal-content {
    padding: 0;

    .modal-header {
      box-shadow: 0 0 8px 0 #211f2326, 0 0 0 1px #211f231a;
    }
  }
}

.region-see-all-cities-modal {
  .modal-body {
    height: 36.25rem;
    padding: 1.5rem;
    overflow-y: auto;

    @include media-breakpoint-down(xs) {
      height: calc(100vh - 3.75rem);
    }
  }
}

.dynamic-directory-filter {
  .dropdown-menu {
    &.align-menu-left {
      top: 3.5rem;
      left: 1.5rem;
    }
  }
}

.directory-companies-filter-modal {
  .modal-body {
    overflow-x: hidden;
    overflow-y: scroll;

    .popover {
      position: relative !important; // stylelint-disable-line declaration-no-important
      margin-top: 4px;
      transform: none !important; // stylelint-disable-line declaration-no-important
    }

    @include media-breakpoint-up(xs) {
      max-height: 34.075rem;
    }

    @include media-breakpoint-down(xs) {
      height: calc(100vh - 9.55rem);
      max-height: 100%;
    }
  }

  .category-group-label {
    font-family: $font-family-questa-sans;
  }

  .custom-control-label {
    margin-left: 1.625rem;
  }
}

.company-list {
  .navbar {
    z-index: 10;
  }
  .sky-loading-indicator-loader {
    right: 15px;
    left: 15px;
  }
  .sticky-top {
    z-index: 1;
  }
}
