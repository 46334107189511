.dropdown-autocomplete {
  .dropdown-menu {
    &::before {
      content: none;
    }

    &::after {
      content: none;
    }

    position: absolute;
    width: 100%;
    max-height: $dropdown-autocomplete-menu-max-height;
    margin-top: 0;
    overflow-y: auto;

    @include media-breakpoint-up(lg) {
      left: 1.5rem;
    }

    .dropdown-item {
      width: 100%;
      height: auto;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      font-size: $font-size-lg;
      white-space: normal;

      &.active,
      &:active {
        color: $dropdown-link-active-color;
        text-decoration: none;
        background-color: rgba($dropdown-link-active-bg, .15);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.dropdown-autocomplete-hero-sea {
    &.dropdown-autocomplete-hero-sea-international {
      .dropdown-menu {
        @include media-breakpoint-up(md) {
          // stylelint-disable declaration-no-important
          width: $international-homepage-hero-form-typeahead-width-desktop !important;
          // stylelint-enable declaration-no-important
        }
      }
    }
    .dropdown-menu {
      max-height: 300px;
      margin-top: 5px;
      overflow-y: auto;
      @include media-breakpoint-up(md) {
        // stylelint-disable declaration-no-important
        width: $postcode-homepage-hero-form-typeahead-width-desktop !important;
        // stylelint-enable declaration-no-important
      }
    }
  }

  &.dropdown-autocomplete-b2b {
    .dropdown-menu {
      max-height: $dropdown-autocomplete-b2b-menu-max-height;
      margin-bottom: 1.5rem;
      @include media-breakpoint-up(lg) {
        // stylelint-disable declaration-no-important
        left: 1.5rem !important;
        // stylelint-enable declaration-no-important
      }
    }
  }
}
