.btn {
  font-family: $btn-font-family;
  line-height: $btn-line-height;
  white-space: normal;
  border: 0;

  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);

  &.btn-hero {
    @include button-size($btn-padding-y-hero, $btn-padding-x-hero, $font-size-hero, $btn-line-height-hero, $btn-border-radius);
  }

  &.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius);
  }
  @include media-breakpoint-up(lg) {
    &.btn-lg-lg {
      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius);
    }
  }

  &.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius);
  }
}

.btn-primary {
  @include button-variant($primary, $primary, $primary-dark, $primary-dark, $primary-dark, $primary-dark, .25);
  &:focus {
    background: $primary;
  }
  color: $white;
}

.btn-secondary {
  @include button-variant($white, $secondary-light, $secondary, $secondary, $secondary, $secondary, .25);
  &:focus {
    color: $secondary;
    background: transparent;
  }
  color: $secondary;
  border-style: solid;
  border-width: $border-width-btn;

  @include button-size(calc(#{$btn-padding-y} - .125rem), calc(#{$btn-padding-x} - .125rem), $font-size-base, $btn-line-height, $btn-border-radius);

  &.btn-hero {
    @include button-size(calc(#{$btn-padding-y-hero} - .125rem), calc(#{$btn-padding-x-hero} - .125rem), $font-size-hero, $btn-line-height-hero, $btn-border-radius);
  }

  &.btn-lg {
    @include button-size(calc(#{$btn-padding-y-lg} - .125rem), calc(#{$btn-padding-x-lg} - .125rem), $font-size-lg, $btn-line-height-lg, $btn-border-radius);
  }

  &.btn-sm {
    @include button-size(calc(#{$btn-padding-y-sm} - .125rem), calc(#{$btn-padding-x-sm} - .125rem), $font-size-sm, $btn-line-height-sm, $btn-border-radius);
  }

  &.btn-arrow {
    &::after {
      background: get-button-arrow-icon($secondary, right) no-repeat;
    }

    &:hover::after {
      background: get-button-arrow-icon($white, right) no-repeat;
    }

    &.btn-arrow-left {
      &::before {
        background: get-button-arrow-icon($secondary, left) no-repeat;
      }

      &:hover::before {
        background: get-button-arrow-icon($white, left) no-repeat;
      }
    }
    &.btn-arrow-down {
      &::after {
        @include arrow-base($font-size-base, 1, $btn-arrow-margin);
        padding-right: 0;
        margin-left: $btn-arrow-margin;
        background: get-button-arrow-icon($secondary, down) no-repeat;
      }

      &:hover::after,
      &:active::after,
      &:active:focus::after {
        background: get-button-arrow-icon($white, down) no-repeat;
      }

      &:focus::after {
        background: get-button-arrow-icon($secondary, down) no-repeat;
      }

      &:disabled::after {
        background: get-button-arrow-icon($gray-900, down) no-repeat;
      }
    }
  }
  &.btn-loading {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::after {
      $btn-loading-icon-side-size: calc(#{$font-size-base} * #{$line-height-xs});

      width: $btn-loading-icon-side-size;
      height: $btn-loading-icon-side-size;
      margin-right: 0;
      margin-left: .5rem;
      content: "";
      background: $custom-btn-loading-icon no-repeat;
    }
  }
}

.btn-secondary-thin {
  @include button-variant($white, $secondary-light, $secondary, $secondary, $secondary, $secondary, .25);
  color: $secondary;
  border-style: solid;
  border-width: $border-width-slim-thin;

  &.btn-sm {
    @include button-size(calc(#{$btn-padding-y-sm} - .0625rem), calc(#{$btn-padding-x-sm} - .3125rem), $font-size-sm, $btn-line-height-sm, $btn-border-radius);
  }

  &.btn-icon {
    &:focus {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.btn-secondary-borderless {
  font-weight: $font-weight-normal;
  color: $secondary;
  background-color: transparent;

  @include hover() {
    color: $secondary;
    text-decoration: none;
    background-color: $secondary-bg;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    color: $secondary;
    background: transparent;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }
}

.btn-pro-char {
  @include button-size(calc(#{$btn-padding-y-sm} - .0625rem), 0, $font-size-sm, $line-height-sm, $btn-pro-char-border-radius);
  width: 2rem;
  font-weight: $font-weight-normal;

  @include media-breakpoint-up(lg) {
    width: 1.75rem;
  }


  &:disabled,
  &.disabled {
    border-color: $gray-200;
    border-style: solid;
    border-width: $border-width-slim-thin;
    opacity: 1;
  }
}

.btn-pro-char-wide {
  width: 2.75rem;

  @include media-breakpoint-up(lg) {
    width: 2.5rem;
  }
}

.btn-link {
  font-weight: $font-weight-normal;
  color: $secondary;
  background-color: transparent;

  @include hover() {
    color: $secondary;
    text-decoration: none;
    background-color: $secondary-bg;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  &.edit-back-button:focus,
  &.edit-back-button:hover, {
    text-decoration: none;
    background-color: transparent;
  }

  &.btn-arrow {
    &::after {
      background: get-button-arrow-icon($secondary, right) no-repeat;
    }

    &.btn-arrow-left {
      &::before {
        background: get-button-arrow-icon($secondary, left) no-repeat;
      }
    }
  }
}

.btn-input-group {
  @include button-variant($white, $input-border-color, $gray-200, $gray-500, $white, $gray-400);
  display: flex;
  padding-right: .5rem;
  padding-left: .5rem;
  box-shadow: none;
}

// stylelint-disable declaration-no-important
// in order to override styles created by button-variant mixins
.radio-tile {

  display: block;
  padding: 1rem .5rem;
  font-weight: $font-weight-bold;
  color: $secondary;
  border: $border-width-btn solid $border-color;
  border-radius: $border-radius;
  box-shadow: none;


  &:hover,
  &:active {
    color: $secondary !important;
    border-color: $secondary-light;
  }

  &:not(:disabled):not(.disabled):active:hover {
    border-color: $secondary;
  }

  .image-container {
    height: 64px;
    @include media-breakpoint-up(lg) {
      height: 76px;
    }
    img {
      object-fit: contain;
      max-width: 100%;
    }
  }

  /* stylelint-disable scss/at-function-named-arguments */
  &:focus {
    border: $border-width-btn solid $secondary-light;
    box-shadow: 0 0 0 $input-btn-focus-width scale-color($secondary-light, $lightness: -75%);
  }

  div {
    height: 3rem;
    white-space: pre-wrap;
  }

  .custom {
    height: 124px;
    @include media-breakpoint-up(lg) {
      height: 136px;
    }
  }
}

@media (hover) {
  .shift-up {
    margin-top: 4px;
  }

  .shift-up:hover {
    transform: translateY(-4px);
  }
}

@media (hover) {
  .shift-up {
    margin-top: 4px;
  }

  .shift-up:hover {
    transform: translateY(-4px);
  }
}

.input-group-append {
  .btn {
    border: $border-width-btn solid $gray-300;
  }
}

// review form submit button has specific margins
.review-form-btn-container {
  margin-top: 1.75rem;
  @include media-breakpoint-up(lg) {
    margin-top: 2.25rem;
  }
}

.btn-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    @include arrow-base($font-size-base, $btn-line-height, $btn-arrow-margin);
    padding-right: 1.5rem;
    margin-left: $btn-arrow-margin;
    background: get-button-arrow-icon($white, right) no-repeat;
  }

  &.btn-arrow-left {
    &::before {
      @include arrow-base($font-size-base, $btn-line-height, $btn-arrow-margin);
      padding-left: 1.5rem;
      margin-right: $btn-arrow-margin;
      background: get-button-arrow-icon($white, left) no-repeat;
    }

    &.no-icon-margin::before {
      margin-right: 0;
    }

    &::after {
      display: none;
    }
  }
}

.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:focus),
  &:active {
    img {
      filter: brightness(0) invert(1);
    }
  }

  img {
    margin-right: .25rem;
  }
}

.copy-button {
  .disabled-state-translation {
    display: none;
  }

  &.copied {
    .initial-state-translation {
      display: none;
    }
    .disabled-state-translation {
      display: inline-block;
    }
  }
}
