$custom-radio-indicator-icon-active: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$primary}'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-radio-indicator-icon-disabled: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$primary-light}'/%3E%3C/svg%3E"), "#", "%23") !default;

$dropdown-select-padding-x: .5rem !default;
$dropdown-select-padding-y: .4375rem !default;

/**
  Dropdown multiselect
 */
.dropdown-select {
  .btn {
    &.disabled {
      background-color: $gray-200;
      border-color: transparentize($border-color, .5);
      opacity: 1;
    }

    position: relative;
    min-height: 2.125rem;
    padding: 0 calc($dropdown-select-padding-x + $arrow-dropdown-size-x) 0 $dropdown-select-padding-x;
    line-height: 1;

    .badge {
      top: 0;
    }

    &::after {
      position: absolute;
      top: calc(50% - #{$dropdown-select-padding-y / 2});
      right: .375rem;
    }
  }

  .select-label {
    color: $gray-500;

    .show & {
      color: $text-color;
    }
  }

  .dropdown-menu {
    font-size: $font-size-sm;
  }

  .dropdown-item {
    padding: $dropdown-select-padding-y $dropdown-select-padding-x;
    line-height: 1;
  }
}

.custom-control-inline {
  padding-left: .8125rem; //radio input width
  &.custom-radio {
    .custom-control-label {
      padding: 0 1rem;
    }
    .custom-control-label::after {
      margin: 0 .7rem;
    }
    .custom-control-label::before {
      margin: 0 .7rem;
    }
  }
}

.custom-control-input + label {
  font-weight: $font-weight-normal;
}

.custom-control-label {
  font-size: $font-size-sm;
  line-height: 1.5rem;

  &.checkbox {
    width: 100%;
    padding: .75rem;
    margin-bottom: 0;
  }

  &.checkbox::after,
  &.checkbox::before {
    margin: .75rem;
  }

  .custom-control-sub-label {
    font-family: $font-family-proxima-nova;
    font-size: 14px;
    font-weight: $font-weight-normal;
    color: $gray-600;
    &.error {
      color: $danger;
    }
  }
}

.custom-control-input ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid $gray-400;
  box-shadow: none;
}

.custom-radio,
.custom-checkbox {
  .custom-control-label {
    width: 100%;
    color: $gray-800;
  }
}

.custom-radio {
  .custom-control-label {
    width: 100%;
    padding: .7rem 1rem;
    font-size: $font-size-sm;
  }
  .custom-control-label::after {
    margin: .7rem;
  }
  .custom-control-label::before {
    margin: .7rem;
  }
}

.custom-radio .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  background-image: $custom-radio-indicator-icon-active;
}

.custom-radio .custom-control-input:checked:disabled ~ .custom-control-label::after {
  background-image: $custom-radio-indicator-icon-disabled;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $white;
}

.custom-select {
  appearance: none;
}

.ngb-dp-weekday.small {
  color: $gray-800;
}

.label-sm {
  margin-bottom: .25rem;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

label {
  font-family: $label-font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  color: $gray-700;

  .help {
    font-size: 12px;
    font-weight: $font-weight-normal;
  }
}

.label-lg {
  font-size: $font-size-lg;
}

.form-control:focus {
  color: $text-color;
  box-shadow: 0 0 4px 0 $accent;
}

.custom-control-input:checked + label {
  font-weight: $font-weight-bold;
}

.sd-btn-checkbox {
  display: inline-block;
  width: 305px;
  max-width: 100%;
  padding: 0;
  text-align: left;
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  box-shadow: none;

  &:not(.disabled):hover {
    border-color: $gray-600;
  }

  label:hover {
    cursor: pointer;
  }
}

.sd-radio-list,
.sd-checkbox-list {
  border-top: 1px solid $border-color;
}

.sd-radio-row,
.sd-checkbox-row {
  font-size: $font-size-sm;
  border-bottom: 1px solid $border-color;

  &:hover {
    background-color: $gray-200;
  }

  .custom-control-input:checked + label {
    font-weight: $font-weight-bold;
  }

  label {
    font-weight: $font-weight-normal;

    &:hover {
      cursor: pointer;
    }
  }

  &.sd-radio-row-bordered {
    margin-bottom: .5rem;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    &.active {
      border: 2px solid $primary;
    }
  }
}

.is-invalid:not(input) {
  color: $danger;
}

.is-invalid {
  border-color: $danger;
}

.form-group.error {
  label {
    color: $danger;
  }
  input,
  textarea {
    border-color: $danger;
  }
  .error-message {
    font-size: $font-size-sm;
    color: $danger;
  }
}

.form-control-plaintext {
  .form-group {
    margin-bottom: 0;
    font-size: $font-size-sm;
  }
  .col-form-label {
    color: $gray-800;
    text-align: right;
    background-color: $gray-300;
    border-right: 1px solid $gray-400;
  }
}

.static-field {
  display: flex;
  flex-direction: column;
  @extend .flex-md-row;

  &:not(:last-of-type) {
    border-bottom: 1px solid $gray-300;
  }

  padding: 12px 0;
  margin: 0 16px;
  @include media-breakpoint-up(md) {
    padding: 10px 0;
  }

  label {
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 1.33;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.43;
    }
  }

  @include media-breakpoint-up(md) {
    &:first-child {
      .label-wrapper,
      .value-wrapper {
        padding-top: 1rem;
      }
    }

    &:last-child {
      .label-wrapper,
      .value-wrapper {
        padding-bottom: 1rem;
      }
    }
  }
}

.sub-label {
  font-size: 12px;
  font-weight: $font-weight-normal;
}

.contact-details-container {
  border-top: solid 1px $gray-300;

  @include media-breakpoint-up(md) {
    border-top: none;
    border-left: solid 1px $gray-300;
  }
}

.row-items {
  font-size: $font-size-sm;
  color: $text-color;

  .data-item {
    padding: .25rem 0;
  }
}

/** Form PIN **/
.pin-inputs {
  display: flex;
  justify-content: flex-start;

  input {
    min-width: 3rem;
    font-size: 1.67rem;
    text-align: center;
  }
}

.datepicker ~ .dropdown-menu {
  min-width: auto;
  font-family: $font-family-sofia-pro;
}

[class*="icon-upload-"] {
  width: $custom-icon-upload-size;
  height: $custom-icon-upload-size;
}

.icon-upload-clear {
  cursor: pointer;
  background-image: $custom-icon-clear-upload;
}

.icon-upload-loading {
  background-image: $custom-icon-loading;
}

// Icons
.input-icon {
  position: absolute;
  top: 0;
  right: -#{$form-validation-icon-width + $form-validation-icon-margin-left};
  width: $form-validation-icon-width;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;

  &.icon-invalid {
    background-image: $custom-icon-invalid;
  }

  &.icon-loading {
    background-image: $custom-icon-loading;
  }

  &.icon-valid {
    background-image: $custom-icon-valid;
  }

  &.icon-search {
    background-image: $custom-icon-search;
  }

  &.icon-clear {
    right: $form-validation-icon-margin-left;
    cursor: pointer;
    background-image: $custom-icon-clear;
  }
}

.sd-form-row-invalid {
  .custom-control-label,
  label {
    color: $danger;
  }

  .form-control,
  button,
  select {
    border-color: $danger;
  }

  .btn-input-group {
    border-left-color: $border-color;
  }

  .custom-control::after {
    @extend .input-icon;
  }

  .form-help-text {
    display: none;
  }
}

.sd-form-row-valid {
  .form-control,
  label,
  button,
  select {
    border-color: $primary;
  }

  .btn-input-group {
    border-left-color: $border-color;
  }
}

.form-help-text {
  font-size: $font-size-sm;
  color: $gray-600;
}

// stylelint-disable selector-no-qualifying-type
// This hotfix can be removed after upgrading bootstrap to v4.1.3.
// See https://github.com/twbs/bootstrap/pull/26820/files
input[type="date"] {
  height: $input-height;
}
// stylelint-enable selector-no-qualifying-type

@import "form-elements/input-block";
