.modal-content {
  padding: 20px;
  border-radius: 0;

  @include media-breakpoint-up(md) {
    padding: 48px;
  }
}

.modal-header {
  margin-bottom: .5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }
}
