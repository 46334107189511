// Quote block

.wp-block-quote {
  padding-left: 1em;
  margin: 0 0 1.75em;
  border-left: .25em solid;

  &.has-text-align-right {
    padding-right: 1em;
    padding-left: 0;
    border-right: .25em solid;
    border-left: none;
  }

  &.has-text-align-center {
    padding-left: 0;
    border: 0;
  }
}

// Tables block

.wp-block-table {
  thead {
    text-align: center;
    border-bottom: 3px solid;
  }

  td,
  th {
    padding: .5em;
    word-break: normal;
    border: 1px solid;
  }

  figcaption {
    font-size: 13px;
    color: #555;
    text-align: center;
  }

  tfoot {
    border-top: 3px solid;
  }
}

