//modal
.modal-content {
  border: 0;
  box-shadow: $all-border-shadow;
}

.modal-body {
  box-shadow: $box-shadow-bottom;

  .top-description {
    margin-bottom: 17px;
    font-size: $font-size-sm;
    line-height: 1.43;
    color: $gray-700;
  }

  .top-label {
    font-weight: $font-weight-bold;
    color: $gray-700;
  }

  .form-group:not(.row) {
    margin-bottom: 0;
  }

  .form-row,
  .sd-form-row {
    padding-top: 1.4375rem;

    &:first-child {
      padding-top: .625rem;
    }

    &:last-child {
      padding-bottom: .625rem;
    }
  }
}

.modal-title {
  font-weight: $font-weight-bold;
  line-height: 1.63;
}

.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-backdrop.in {
  opacity: .7;
}

.modal-header {
  align-items: center;
  padding: 17px 20px 19px;
  border: 0;
  box-shadow: $box-shadow-bottom;
}

.modal-footer {
  padding: .875rem 1.25rem;
  border: 0;

  .btn {
    margin-right: 0;
    margin-left: .625rem;

    @include media-breakpoint-down(md) {
      &.btn-block {
        margin-left: 0;
      }
    }
  }

  .btn-container-vertical-md-horizontal {
    display: flex;
    flex-direction: column;

    .btn {
      width: 100%;
      margin-bottom: .5rem;
      margin-left: 0;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      float: right;

      .btn {
        width: auto;
        margin-bottom: 0;
        margin-left: .625rem;
      }
    }
  }
}

.google-maps-container {
  .angular-google-map-container {
    height: 400px;
  }
}
