.rating-label {
  display: inline-block;
  padding: 2px 10px;
  font-family: $font-family-hk-grotesk;
  color: $white;
  border-radius: 0;
}

.rating {
  font-weight: $font-weight-bold;
  color: $white;
  &.background-white {
    color: $rating-unknown;
  }
}

.rating-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 28px;

  &.responsive-rating {
    width: auto;
    height: auto;
    padding: 0 .5rem;
    font-size: $font-size-sm;
    line-height: 1.5rem;
  }
}

.company-rating-unknown {
  &.rating-box {
    background-color: $rating-unknown;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
  }
}

.company-rating-very-bad,
.rating-bad {
  &.rating-box {
    background-color: $rating-bad;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
    &.background-white {
      color: $rating-bad;
    }
  }
}

.company-rating-bad,
.rating-poor {
  &.rating-box {
    background-color: $rating-medium;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
    &.background-white {
      color: $rating-medium;
    }
  }
}

.company-rating-medium,
.rating-fair {
  &.rating-box {
    background-color: $rating-good;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
    &.background-white {
      color: $rating-good;
    }
  }
}

.company-rating-good,
.rating-good {
  &.rating-box {
    background-color: $rating-good;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
    &.background-white {
      color: $rating-good;
    }
  }
}

.company-rating-very-good,
.rating-excellent {
  &.rating-box {
    background-color: $rating-good;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
    &.background-white {
      color: $rating-good;
    }
  }
}

.already-submitted {
  margin-top: 100px;
}

/**
  Review form
 */
/* stylelint-disable selector-max-class */
.review-form-container {
  .ratings {
    //removes outline (blue line in chrome) from rating (star) elements
    * {
      &:focus {
        outline: 0;
      }
    }

    .rating {
      .rating-container {
        max-height: 32px;
      }
      .rating-star {
        display: inline-block;
        width: 32px;
        height: 32px;
        color: $white;
        background-color: $rating-unknown;
        background-image: url("../images/move/icons/svg/rating-star.svg");
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid $rating-unknown;
        border-radius: 50%;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &.rating-bad {
        .rating-star {
          @include star-rating-status($rating-bad);
        }
      }

      &.rating-poor {
        .rating-star {
          @include star-rating-status($rating-medium);
        }
      }

      &.rating-fair {
        .rating-star {
          @include star-rating-status($rating-good);
        }
      }

      &.rating-good {
        .rating-star {
          @include star-rating-status($rating-good);
        }
      }

      &.rating-excellent {
        .rating-star {
          @include star-rating-status($rating-good);
        }
      }
    }
  }

  .review-input-error {
    margin-bottom: 0;
    border: $input-border-width solid $danger;
  }

  .text-error {
    color: $danger;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
    &.background-white {
      color: $rating-good;
    }
  }
}
/* stylelint-enable selector-max-class */
.review {
  .rating {
    font-weight: $font-weight-bold;
    color: $white;
  }
}

.review-summary {
  .review-count {
    @extend .font-size-md;
  }

  .rating-box {
    width: 64px;

    &.responsive-rating {
      width: auto;
      height: auto;
      padding: .125rem .5rem;
      font-size: $font-size-sm;
      line-height: 1.5rem;
    }
  }
}

.review-ratings {
  // used with s-block
  width: 170px;
  // used with d-flex
  min-width: 170px;
}

// Review reply
.review-reply {
  border-radius: 0;
}
