// Autocomplete widget
// stylelint-disable declaration-no-important

.pac-container {
  margin-top: 4px;
  .pac-icon {
    display: none;
  }

  .pac-item,
  .pac-item-query,
  .pac-matched {
    font-family: $font-family-sofia-pro;
    font-size: 14px;
  }

  .pac-item {
    padding: 8px 16px;
    color: $gray-600;
    border-top: none;
    border-bottom: none;
    &:hover {
      cursor: pointer;
      background-color: $gray-200 !important;
    }
  }

  .pac-item-selected {
    background-color: $gray-200 !important;
  }

  .pac-item-query {
    color: $gray-800;
  }

  .pac-matched {
    font-weight: $font-weight-bold;
    color: $gray-800;
  }
  border-radius: 4px;
  &::after {
    height: 0;
    background-image: none !important;
  }
}
