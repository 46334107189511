// stylelint-disable declaration-no-important
// required because default w-100 class also has important declaration
.w-lg-auto {
  @include media-breakpoint-up(md) {
    width: auto !important;
  }
}

.h-lg-auto {
  // important needed to override any other classes setting height
  @include media-breakpoint-up(md) {
    height: auto !important;
  }
}

// stylelint-disable-next-line selector-class-pattern
.h-2_5 {
  height: 2.5 * $spacer;
}

// stylelint-disable-next-line selector-class-pattern
.mh-2_5 {
  max-height: 2.5 * $spacer;

  &.mh-lg-3 {
    @include media-breakpoint-up(lg) {
      max-height: 3 * $spacer;
    }
  }
}

.h-4 {
  height: 4 * $spacer;
  @include media-breakpoint-up(sm) {
    &.h-md-3 {
      height: 3 * $spacer;
    }
  }
}

.col-6 {
  @include media-breakpoint-down(sm) {
    &.col-6-closer {
      &:nth-child(2n-1) {
        padding-right: 8px;
      }

      &:nth-child(2n) {
        padding-left: 8px;
      }
    }
  }
}

.row.no-wrap .col,
.row.no-wrap > [class^="col-"] {
  &.min-w-300px {
    min-width: 300px;
  }
}

// this allows us to extend the w-* sizing utilities with media breakpoints
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }

  }
}
