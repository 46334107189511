.category-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  li {
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      margin-bottom: .5 * $spacer;
    }
    a {
      display: block;
      padding-top: .5 * $spacer;
      padding-bottom: .5 * $spacer;
      color: $gray-700;
      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }
  }
  // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
  & + .category-list {
    li:last-child {
      margin-bottom: 0;
    }
  }
}

.nap-items-list {
  .nap-item {
    margin-left: .5rem;
  }

  .nap-item:first-child {
    margin-left: 0;
  }
}
