//Custom styling for datepicker buttons
.uib-datepicker-popup {
  .btn {
    padding: .375rem .45rem;
    line-height: 1.42;
    border: 0;
    border-radius: 0;
  }

  &.dropdown-menu {
    position: absolute;
  }
}

//stylelint-disable declaration-no-important
.datepicker-input {
  background-color: $white !important;
  &:disabled {
    background-color: $gray-300 !important;
  }
}
