// INLINE ALERT STYLES
.alert {
  padding: .75rem 1.25rem;
  color: $gray-700;
  border: 0;
  border-radius: 0;

  &.alert-danger {
    color: $danger-dark;
    background-color: $danger-bg;
  }

  &.alert-warning {
    color: $warning-dark;
    background-color: $warning-bg;
  }

  &.alert-success {
    color: $primary-dark;
    background-color: $success-bg;
  }

  &.alert-secondary {
    color: $secondary-dark;
    background-color: $secondary-bg;
  }
}

// APP ALERT STYLES

.alert-container {
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  z-index: 1200;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  .alert-text {
    position: relative;
    display: inline-block;
    max-width: 90%;
    padding: .75em 1.25em;
    margin: 0 auto;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $white;
    box-shadow: 0 4px 16px 0 rgba(22, 23, 26, .25);

    &.danger {
      background-color: $danger;
    }

    &.warning {
      background-color: $warning;
    }

    &.success {
      background-color: $success;
    }

    &.secondary {
      background-color: $secondary;
    }
  }
}
//Form header alert
.form-header-alert {
  display: flex;
  flex-wrap: nowrap;
  padding: 16px 20px;
  margin: -20px -20px 20px;
  color: $gray-700;

  @include media-breakpoint-up(md) {
    align-items: center;
    padding: 24px;
    margin: -32px;
  }

  @include media-breakpoint-up(lg) {
    margin: -50px -50px 48px;
  }
}

