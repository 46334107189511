@function get-colored-icon($icon, $color) {
  @return str-replace(str-replace($icon, "[[fillColor]]", $color), "#", "%23");
}

@function get-check-icon($color) {
  $check-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='[[fillColor]]' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.5 12.5l5 5 12-12'/%3E%3C/svg%3E%0A");
  @return get-colored-icon($check-icon, $color);
}

@function get-check-icon-outlined($color) {
  $check-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='[[fillColor]]' stroke-linecap='round' stroke-linejoin='round' d='M8 14.5A6.5 6.5 0 1 0 1.5 8c0 3.5 2.397 4.816 4 6 .377.278-2.535-1.23-2-1 .997.428 3 1.5 4.5 1.5zm-3-6 2.5 2C8.5 8.833 9.667 7.333 11 6'/%3E%3C/svg%3E%0A");
  @return get-colored-icon($check-icon, $color);
}

@function get-radio-icon($color) {
  $radio-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2.6' fill='[[fillColor]]'/%3E%3C/svg%3E");
  @return get-colored-icon($radio-icon, $color);
}

@function get-menu-icon($color) {
  $icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='[[fillColor]]' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3.1 5.9c1.93.21 4.562.247 7.897.108C16 5.8 19.765 6.115 20 6.1c.326-.02.659-.054 1-.1M3 12.2c-.112-.211.222-.311 1-.3 3.31.047 8.782.199 11 .1 3-.133 5-.133 6 0M3 18c-.667-.067.833-.1 4.5-.1 1.949 0 4.08.181 6.5.2 1.422.011 3.755-.022 7-.1'/%3E%3C/svg%3E");
  @return get-colored-icon($icon, $color);
}

@function get-loading-icon($secondary, $secondary-bg) {
  $icon:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='[[secondary-bg]]' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='[[secondary]]' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") !default;
  @return str-replace(str-replace(str-replace($icon, "[[secondary]]", $secondary), "[[secondary-bg]]", $secondary-bg), "#", "%23");
}

@mixin check-icon($size: 1rem, $color: $gray-800) {
  display: inline-block;
  width: $size;
  min-width: $size; //Prevent resizing
  height: $size;
  background-image: get-check-icon($color);
}

@mixin check-icon-outline($size: 1rem, $color: $gray-800) {
  display: inline-block;
  width: $size;
  min-width: $size; //Prevent resizing
  height: $size;
  background-image: get-check-icon-outlined($color);
}

@mixin radio-icon($size: 1rem, $color: $gray-800) {
  display: inline-block;
  width: $size;
  height: $size;
  background-image: get-radio-icon($color);
}
