$loader-width: 2.5rem !default;
$loader-height: $loader-width !default;
$iframe-loading-min-height: 400px !default;
$btn-loading-margin-left: .5rem !default;

.loader {
  width: $loader-width;
  height: $loader-height;
  background-image: get-loading-icon($secondary, $secondary-bg);
}

.iframe-loading {
  height: 100%;
  min-height: $iframe-loading-min-height;
  background: $custom-icon-loading center center no-repeat;
  background-size: $loader-width $loader-height;
}

.btn-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    $btn-loading-icon-side-size: calc(#{$font-size-base} * 1);
    $loader-shift: calc(#{$btn-loading-icon-side-size} + #{$btn-loading-margin-left});

    display: block;
    width: $btn-loading-icon-side-size;
    height: $btn-loading-icon-side-size;
    margin-right: calc(#{$loader-shift} * -1);
    margin-left: $btn-loading-margin-left;
    content: "";
    background: $custom-btn-loading-icon no-repeat;
  }
}

.form-loader {
  padding: 20px 20px 262px;
  margin: -20px;
  background-color: $gray-100;

  @include media-breakpoint-up(md) {
    margin: -32px;
  }

  @include media-breakpoint-up(lg) {
    padding: 50px 50px 400px;
    margin: -50px;
  }
}

.sky-loading-indicator-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background-color: $gray-100;
}

.loading-icon {
  $loading-icon-size: 46px;
  width: $loading-icon-size;
  height: $loading-icon-size;
  background-image: get-loading-icon($secondary, $gray-300);
  background-size: $loading-icon-size;
}

.b2b-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  background: $white;
}

.loading-box {
  display: flex;
  justify-content: center;
  width: 100%;

  background-color: $gray-100 !important; // stylelint-disable-line declaration-no-important
  background-image: get-loading-icon($secondary, $gray-300);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;

  > div {
    opacity: .1;
  }
}
