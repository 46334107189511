.company-profile-carousel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.company-profile-carousel-wrap::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #1a1817;
  opacity: .85;
}

.company-profile-carousel {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);

  .carousel-caption {
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    background: linear-gradient(to bottom, rgba(26, 24, 23, 0), rgba(26, 24, 23, .5));
    opacity: .85;
  }
}

.company-profile-photos {
  margin-right: .5rem;
  margin-bottom: 1rem;

  img {
    width: 15rem;
    height: 10rem;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      width: 11.25rem;
      height: 7.5rem;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.carousel-image {
  display: block;
  max-width: calc(100vw - 10vw);
  max-height: calc(100vh - 25vh);

  @media screen and (max-device-height: 440px) {
    max-height: calc(100vh - 35vh);
  }
}

.carousel-controls-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 10000;
  margin-bottom: 2rem;
  transform: translateX(-50%);

  @media screen and (max-device-height: 540px) {
    margin-bottom: 1rem;
  }
}

.carousel-controls-text {
  width: 5.5rem;
  font-size: $font-size-sm;
  line-height: 1.25;
  color: $white;
  text-align: center;
}

.carousel-close-container {
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 10000;
}

.carousel-action-btn {
  border: 1px solid $secondary-light-blue;
  @include button-size(calc(.5rem - .0625rem), calc(.5rem - .0625rem), $font-size-xs, 1, $btn-border-radius);
}

.hide-scroll-bar {
  overflow: hidden;
  // hide scroll bar style for ie
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.preview-loader-padding {
  padding: 50px;

  @include media-breakpoint-down(sm) {
    padding: 40px;
  }
}
