.menu-links {

  [class*="col-"] {
    margin-bottom: 1rem;

    &:last-child
    {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .col-6:last-child,
  .col-6:nth-last-child(2) {
    margin-bottom: 0;

    li:last-child {
      margin-bottom: 0;
    }
  }

  .menu-nav {
    flex-direction: column;
    margin-bottom: 0;

    li {
      a {
        @extend .w-100;
        display: inline-block;
        padding: .5rem 0;
      }
      @include media-breakpoint-up(lg) {
        a {
          padding: 0;
        }
        margin-bottom: .5rem;
      }
      &:last-child {
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: $gray-700;
    }
  }

}
