$component-margin-y: 2rem !default;
$component-margin-y-desktop: 2rem !default;

$component-large-margin-y: 3rem !default;
$component-large-frontpage-margin-y: 3rem !default;
$component-large-margin-y-desktop: 6rem !default;

$panel-margin-y: 1rem !default;
$panel-margin-y-desktop: 2rem !default;
$sd-outer-container-width: 1440px !default;

$cta-margin-y: 1.5rem !default;
$cta-margin-y-desktop: 2.5rem !default;

html,
body {
  background-color: $white;
}

body {
  display: flex;
  flex-direction: column;
}

.sd-outer-container {
  z-index: 1;
  max-width: $sd-outer-container-width;
  margin: 0 auto;
  overflow-x: hidden;
}

.b2c-page-container {
  @extend .container;
  margin-top: 2rem;
  @include media-breakpoint-up(lg) {
    margin-top: 3.5rem;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.navbar,
.footer {
  flex-shrink: 0;
}

.row.no-wrap {
  flex-wrap: nowrap;
  overflow-x: auto;

  .col,
  > [class^="col-"] {
    min-width: 270px;
  }
}

//Component containers

.static-page-container {
  margin-top: 2rem;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    margin-top: 3.5rem;
  }
}

.component-container {
  margin: $component-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $component-margin-y-desktop 0;
  }
}

.component-container-large {
  margin: $component-large-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $component-large-margin-y-desktop 0;
  }
}

.panel-container {
  margin: $panel-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $panel-margin-y-desktop 0;
  }
}

.call-to-action-container {
  margin: $cta-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $cta-margin-y-desktop 0;
  }
}

.frontpage-component-container {
  margin: $component-large-frontpage-margin-y 0;

  @include media-breakpoint-up(lg) {
    margin: $component-large-margin-y-desktop 0;
  }
}

.container-company-logos {
  margin-top: 2.5rem;

  @include media-breakpoint-up(lg) {
    margin-top: 5rem;
  }
}

.component-container:last-child,
.component-container-large:last-child,
.panel-container:last-child,
.frontpage-component-container:last-child {
  margin-bottom: 0;
}

.row.row-narrow {
  @include media-breakpoint-down(md) {
    margin-right: -8px;
    margin-left: -8px;

    .col,
    > [class^="col-"] {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}
