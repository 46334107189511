@import "modal";

//modal
.modal-content {
  padding: 20px;

  @include media-breakpoint-up(md) {
    padding: 50px;
  }

  .modal-close-button {
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      background-color: transparent;
    }
  }
}

.modal-header {
  padding: 0 28px 0 0;
  margin-bottom: .75rem;
  box-shadow: none;

  @include media-breakpoint-up(md) {
    padding-right: 0;
    margin-bottom: 1rem;
  }
}

.modal-body {
  padding: 0;
  box-shadow: none;
}

.modal-footer {
  padding: 0;
  margin-top: .5rem;

  @include media-breakpoint-up(md) {
    margin-top: 1rem;
  }
}
