$list-group-check-mark-padding-left: 8px !default;
$list-group-check-mark-icon-width: 20px !default;
$list-group-check-mark-icon-height: $list-group-check-mark-icon-width !default;
$list-group-check-mark-font-size: 18px !default;
$list-group-check-mark-line-height: 1.45 !default;
$list-group-check-mark-item-margin-bottom: 4px !default;

.list-group-check {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
  .list-group-check-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: $list-group-check-mark-padding-left + $list-group-check-mark-icon-width;
    margin-bottom: 0;
    font-size: $list-group-check-mark-font-size;
    line-height: $list-group-check-mark-line-height;
    border: 0;
    &::before {
      position: absolute;
      top: ($list-group-check-mark-font-size * $list-group-check-mark-line-height - $list-group-check-mark-icon-height) / 2;
      left: 0;
      display: inline-block;
      width: $list-group-check-mark-icon-width;
      height: $list-group-check-mark-icon-height;
      content: "";
      border: 0;
    }
    @include media-breakpoint-up(md) {
      font-size: $list-group-check-mark-font-size;
      line-height: $list-group-check-mark-line-height;
      &::before {
        top: ($list-group-check-mark-font-size * $list-group-check-mark-line-height - $list-group-check-mark-icon-height) / 2;
      }
    }
    &.list-group-check-item-success::before {
      background: $custom-icon-check-success;
      background-size: $list-group-check-mark-icon-width $list-group-check-mark-icon-height;
    }
    &.list-group-check-item-secondary::before {
      background: $custom-icon-check-secondary;
      background-size: $list-group-check-mark-icon-width $list-group-check-mark-icon-height;
    }
    &.list-group-check-item-gray-500::before {
      background: $custom-icon-check-gray-500;
      background-size: $list-group-check-mark-icon-width $list-group-check-mark-icon-height;
    }
    &:not(:last-of-type) {
      margin-bottom: $list-group-check-mark-item-margin-bottom;
    }
  }
}
