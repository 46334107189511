.cp-nap-buttons-block {
  .nap-item {
    margin-right: .5rem;
  }

  .nap-item:last-child {
    margin-right: 0;
  }
}

.reviews-description {
  padding: .75rem 1rem;
  color: $secondary-dark;
  background-color: $secondary-bg;
}

.anonymous-review-question-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.2;
  color: $white;
  text-align: center;
  background-color: $secondary;
  border: 1px solid $secondary;
  border-radius: 50%;
  &.active,
  &[aria-describedby]{
    color: $secondary;
    background-color: $white;
  }
  &:hover {
    cursor: pointer;
  }
}
