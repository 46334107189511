$static-text-block-y: $spacer !default;
$static-text-block-bottom: $static-text-block-y !default;
$static-text-block-top: $static-text-block-y !default;

$static-text-list-top: $static-text-block-y !default;
$static-text-list-bottom: $static-text-block-y !default;

$static-text-image-margin-y: $spacer * 1.5 !default;
$static-text-image-margin-top: $static-text-image-margin-y !default;
$static-text-image-margin-bottom: $static-text-image-margin-y !default;

.static-text.intro,
.static-text.intro p {
  font-size: $font-size-lg;
}

.static-text {
  @import "tablepress";
  @import "./headers_margins";

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child {
    margin-top: 0;
  }

  color: $gray-700;

  h4 {
    font-size: $font-size-x-lg;
  }

  p {
    margin-bottom: $static-text-block-bottom;

    img {
      margin-top: $static-text-image-margin-top;
      margin-bottom: $static-text-image-margin-bottom;
    }
  }

  a {
    color: $secondary;

    &:hover {
      color: $secondary; //otherwise the typography selector becomes stronger
      // stylelint-disable selector-no-qualifying-type
      &.btn-secondary {
        color: $white; // do not overwrite buttons
      }
      // stylelint-enable selector-no-qualifying-type
    }
  }

  ul,
  ol:not(.breadcrumb) {
    padding-left: 53px;
    margin-top: $static-text-list-top;
    margin-bottom: $static-text-list-bottom;
    list-style-type: none;

    li {
      position: relative;
    }

    li::before {
      position: absolute;
      left: -27px;
      content: "•";
    }
  }

  /* Selector needs to be more strict to override only wordpress-sized images */
  /* stylelint-disable selector-no-qualifying-type */
  img[class*="align"],
  img[class*="wp-image-"] {
    display: block;
    float: none;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
}
