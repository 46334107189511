@function get-button-arrow-icon-base($direction) {
  $arrow-dropdown-icon-left: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='[[fillColor]]' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12l18 .2a92.73 92.73 0 0 0-10.305-.153L9.5 12.1c-1.694.085-3.86.051-6.5-.1zm7 7c-3.5-3-7-7-7-7l.192-.27C3.8 10.91 5.882 8.295 10 5'/%3E%3C/svg%3E%0A"), "#", "%23");
  $arrow-dropdown-icon-right: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='[[fillColor]]' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12.2c3.667-.267 7.5-.3 11.5-.1 1.694.085 3.86.051 6.5-.1m-7 7c3.5-3 7-7 7-7s-2-3-7-7'/%3E%3C/svg%3E%0A"), "#", "%23");
  $arrow-dropdown-icon-down: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='[[fillColor]]' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7.867 2c.177 2.444.2 5 .066 7.667C7.877 10.796 7.9 12.24 8 14M3.333 9.333C5.333 11.667 8 14 8 14s2-1.333 4.667-4.667'/%3E%3C/svg%3E%0A"), "#", "%23");

  @if ($direction == left) {
    @return $arrow-dropdown-icon-left;
  } @else if ($direction == down) {
    @return $arrow-dropdown-icon-down;
  } @else {
    @return $arrow-dropdown-icon-right;
  }
}

@function get-button-arrow-icon($fillColor, $direction) {
  $icon: get-button-arrow-icon-base($direction);
  @return str-replace(str-replace($icon, "[[fillColor]]", $fillColor), "#", "%23");
}

@mixin arrow-base($font-size-base, $btn-line-height, $btn-arrow-margin) {
  $btn-arrow-icon-side-size: calc(#{$font-size-base} * #{$btn-line-height});
  $loader-shift: calc(#{$btn-arrow-icon-side-size} + #{$btn-arrow-margin});

  display: block;
  width: $btn-arrow-icon-side-size;
  height: $btn-arrow-icon-side-size;
  content: "";
}

