.sticky-form{
  border-top: solid $border-width rgba(115, 107, 103, .15);
  box-shadow: 0 4px 16px 0 rgba(26, 24, 23, .15);
  .close{
    position: absolute;
    top: 0;
    right: 0;
    opacity: 1;
    @include media-breakpoint-down(md) {
      right: 15px;
    }
  }
}
