//not used anywhere, left in case it's needed in the future designs
.radio-pills {
  display: flex;
  margin-bottom: 8px;
  overflow: auto;

  //Begin of the overflow shadows
  //This is more readable, but I'm breaking multiple rules when putting background on multiple lines
  //stylelint-disable
  background: linear-gradient(to right, $white 30%, rgba(255, 255, 255, 0)),
  linear-gradient(to right, rgba(255, 255, 255, 0), $white 70%),
  linear-gradient(to right, rgba(0, 0, 0, .1), rgba(255, 255, 255, 0)),
  linear-gradient(to left, rgba(0, 0, 0, .1), rgba(255, 255, 255, 0));

  background-color: $white;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-position: left center, right center, left center, right center;
  background-size: 20px 100%, 20px 100%, 8px 100%, 8px 100%;
  //stylelint-enable
  //End of overflow shadows

  box-shadow: 0 3px 0 0 rgba(51, 20, 5, .03);

  input {
    display: none;
  }

  label {
    flex: 1 1 auto;
    padding: 8px 12px;
    margin: 0;
    font-size: 14px;
    line-height: 1.71;
    color: $secondary;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;

    @include media-breakpoint-up(md) {
      padding: 3px 12px;
      font-size: 16px;
      line-height: 1.5;
    }

    &::after {
      float: right;
      height: 30px; //100% of parent + 10px
      margin-top: -5px;
      margin-right: -13px; //the padding-x of the parent - the border width
      margin-bottom: -5px; //Prevent the div from growing as the height is bigger than the container
      content: "";
      border-right: 1px solid $gray-300;
      @include media-breakpoint-up(md) {
        height: 22px; //100% of parent + 10px
        margin-top: 0;
      }
    }

    &:first-of-type {
      border-left: 1px solid $gray-300;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-right: 1px solid $gray-300;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  input:checked + label {
    padding: 7px 10px; //Decrease padding by one to prevent size increase because of increased border width (horizontally not perfect as first, middle and last child all have different border changes)
    color: $gray-800;
    border: 2px solid $secondary;
    border-radius: 4px;

    &::after {
      content: none;
    }

    @include media-breakpoint-up(md) {
      padding: 2px 10px; //Decrease padding by one to prevent size increase because of increased border width
    }
  }
}
