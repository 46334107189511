.selected-companies-v2-wrapper {
  &:last-child {
    margin-bottom: 0;
  }

  .company-location-row {
    a {
      white-space: nowrap;
      visibility: hidden;
    }
    p {
      /* stylelint-disable */
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &.expanded {
        display: block;
      }
      /* stylelint-enable */
    }
    &.show-more {
      a {
        visibility: visible;
      }
    }
  }

  .selected-company-v2 {
    margin-bottom: 2rem;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

