.footer-nav {
  margin-bottom: 0;
}

.footer {
  margin-top: 3rem;
  color: $white;
  background-color: $secondary-dark;

  @include media-breakpoint-up(lg) {
    margin-top: 6rem;
  }

  .footer-links {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    h5 {
      margin-bottom: 1rem;
      color: $white;
    }

    .nav {
      flex-direction: column;

      li {
        display: flex;
        word-break: break-word;

        &:last-child {
          margin-bottom: 0;
        }
      }
      // stylelint-disable selector-no-qualifying-type
      a {
        &[href*="mailto"],
        &[href*="tel"] {
          word-break: break-all;
        }
      }
      // stylelint-enable selector-no-qualifying-type
    }

    @include media-breakpoint-up(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  a {
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: $gray-300;

    &:hover {
      color: $gray-300;
    }

    @include media-breakpoint-up(md) {
      padding-top: .25rem;
      padding-bottom: .25rem;
    }
  }

  .footer-bottom-list {
    // reduced bottom padding to compensate bottom links' padding
    padding: 1.5rem 0 1rem;
    @include media-breakpoint-up(md) {
      padding-bottom: 1.25rem;
    }
    background-color: $secondary-darker;

    .nav {
      justify-content: center;
      float: none;

      @include media-breakpoint-up(md) {
        float: left;
      }

      li {
        display: flex;
        padding-right: 1rem;
        padding-left: 1rem;

        @include media-breakpoint-up(md) {
          width: auto;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .copyright {
      justify-content: center;
      margin-bottom: .5rem;
      text-align: center;

      @include media-breakpoint-up(md) {
        float: left;
        padding-top: .25rem;
        padding-bottom: .25rem;
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }

  [class*="col-"] {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .phone-number {
    font-family: $font-family-questa-sans;
    font-size: $font-size-base * 1.375;
    line-height: $font-size-base * 1.75;
    @include media-breakpoint-up(lg) {
      font-size: $font-size-base * 1.5;
      line-height: $font-size-base * 2;
    }
  }
}

// stylelint-disable selector-no-qualifying-type, selector-type-no-unknown
sticky-form.visible ~ move-footer{
  margin-bottom: calc(#{2 * .5 * $spacer + 2 * $btn-padding-y + $btn-line-height} + #{$border-width});
  @include media-breakpoint-up(md) {
    margin-bottom: calc(#{2 * 1.25 * $spacer + 2 * $btn-padding-y + $btn-line-height} + #{$border-width});
  }
}
// stylelint-enable selector-no-qualifying-type, selector-type-no-unknown
