.bg-gray-light {
  background-color: $gray-300;
  .rating {
    color: $gray-800;
  }
}
.bg-primary-light {
  background-color: $primary-light;
  .rating {
    color: $primary;
  }
}
.bg-warning-light {
  background-color: $warning-light;
  .rating {
    color: $warning;
  }
}
.bg-danger-light {
  background-color: $danger-light;
  .rating {
    color: $danger;
  }
}

.rating-label {
  display: inline-block;
  padding: 2px 10px;
  font-family: $font-family-sofia-pro;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $gray-700;
  border-radius: 2px;
}

.rating-star {
  display: inline-block;
  color: $gray-400;

  &.star-sm {
    width: 1.25rem;
    height: 1.25rem;

    margin: 2px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.star-md {
    width: 1.5rem;
    height: 1.5rem;

    margin: 4px 2px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.company-rating-unknown {
  &.rating-box {
    background-color: $gray-300;
  }
  .rating {
    color: $gray-700;
  }
}

.company-rating-very-bad {
  &.rating-box {
    background-color: $danger-light;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $danger;
  }
}
.company-rating-bad {
  &.rating-box {
    background-color: $warning-light;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $warning;
  }
}
.company-rating-medium {
  &.rating-box {
    background-color: $primary-light;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $primary;
  }
}
.company-rating-good {
  &.rating-box {
    background-color: $primary-light;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $primary;
  }
}
.company-rating-very-good {
  &.rating-box {
    background-color: $primary-light;
  }
  .rating {
    font-weight: $font-weight-bold;
    color: $primary;
  }
}

.rating-summary {
  padding: 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.review-summary {
  display: flex;
  flex-direction: row;
  align-items: center;

  .review-summary-label {
    margin: 0 1rem 0 0;
  }

  .review-count {
    padding-left: .625rem;
    @extend .smaller;
  }
}

// Review reply
.review-reply {
  background-color: $gray-200;
  border-radius: $border-radius-sm;
}
