@import "mixins/icons";

.check-white {
  @include check-icon(1.25rem, $white);
}

.check-secondary {
  @include check-icon(1.5rem, $secondary);
}

.check-secondary-outlined {
  @include check-icon-outline(1rem, $secondary);
}

.check-success {
  @include check-icon(1.5rem, $success);
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid $gray-300;
}

.testimonial-icon {
  @extend .profile-icon;
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  img {
    width: 4rem;
    height: 4rem;
  }

  @include media-breakpoint-up(sm) {
    width: 7.5rem;
    height: 7.5rem;
    img {
      width: 5rem;
      height: 5rem;
    }
  }
}

.icon-search {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.icon-modify-verification-phone {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  cursor: pointer;
  transform: translateY(-50%);

  &:active {
    filter: brightness(0) saturate(100%) invert(55%) sepia(41%) saturate(3159%) hue-rotate(109deg) brightness(100%) contrast(85%);
  }

  &:hover {
    filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(1062%) hue-rotate(116deg) brightness(92%) contrast(101%);
  }
}
