.company-logo-container-wrapper {
  max-width: 270px;

  .company-logo-container {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    height: 84px;

    img {
      max-width: 100%;
      max-height: 56px;
    }

    padding: 14px 7px;
    @include media-breakpoint-up(md) {
      height: 130px;
      padding: 20px 18px;
      img {
        max-height: 80px;
      }
    }
    border: $border-width solid $gray-300;
  }

}

.companyprofile-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  background-color: $white;
  border: 1px solid $gray-300;

  &.pro {
    height: 128px;
    @include media-breakpoint-up(md) {
      height: 188px;
    }
  }

  &.company-pro-list-logo {
    width: 176px;
    height: 98px;
    padding: 1rem .875rem;
    @include media-breakpoint-down(xs) {
      width: 80px;
      height: 80px;
      padding: .875rem .375rem;
    }
  }

  &.selected-company-list-logo {
    width: 176px;
    height: 138px;
    padding: 1.5rem .75rem;
    @include media-breakpoint-down(xs) {
      width: 80px;
      height: 80px;
      padding: .75rem .5rem;
    }
  }

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.directory-top-banner{
  height: 25vh;
  max-height: 320px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
