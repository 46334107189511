.hero-container {
  @extend .container;
  position: relative;
  height: 552px;
  padding-top: #{$navbar-height + 32px};
  padding-bottom: 4rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    height: 666px;
    padding-top: 11rem;
  }
}

.hero-text {
  text-shadow: 0 0 16px rgba(0, 0, 0, .6);
  @include media-breakpoint-up(lg) {
    text-shadow: none;
  }

  &.hero-title {
    font-size: 38px;
    line-height: 44px;
    color: $white;
    @include media-breakpoint-up(lg) {
      font-size: 48px;
      line-height: 56px;
      color: $gray-800;
    }
  }

  &.hero-description {
    @extend .p-x-large;

    color: $white;
    @include media-breakpoint-up(lg) {
      color: $gray-600;
    }
  }
}

.postcode-hero-form-container {
  .postcode-hero-panel {
    @extend .border-0;
    @include media-breakpoint-up(md) {
      /* stylelint-disable declaration-no-important */
      padding: $postcode-homepage-hero-form-padding-desktop !important;
      /* stylelint-enable declaration-no-important */
    }
  }
}

.homepage-hero {

  @include media-breakpoint-up(md) {
    min-height: $homepage-hero-desktop-height - $homepage-hero-desktop-margin;
  }

  .hero-image-homepage-container {
    margin-right: -15px;
    margin-left: -15px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: -$homepage-hero-desktop-margin;
      right: 0;
      width: 470px;
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }

    .hero-image-homepage {
      position: relative;
      height: $homepage-hero-desktop-height;
      margin-top: -72px;
      margin-left: -96px;
      background-image: url("../images/move/hero-move-redesign.jpg");

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background-image: linear-gradient(to bottom, rgba(46, 74, 153, 0), rgba(46, 74, 153, .3));
      }

      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 0;
        &::before {
          background-image: none;
          box-shadow: 8px 8px 0 0 rgba(20, 48, 102, .03);
        }
      }
    }
  }

  .postcode-hero-panel {
    form {
      .hero-postcode-input {
        text-transform: uppercase;
      }

      /* stylelint-disable selector-no-qualifying-type */
      &.au-moving24-com,
      &.za-moving24-com {
        .hero-postcode-input {
          text-transform: none;
        }
      }
      /* stylelint-enable selector-no-qualifying-type */
    }

    @include media-breakpoint-up(md) {
      width: $postcode-homepage-hero-form-width-desktop;
    }
  }

  .custom-margin-hero {
    margin-top: -282px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}

.hero-category-and-sea {
  @include media-breakpoint-up(lg) {
    height: 509px;
  }
  margin-bottom: .5rem;

  .hero-content {
    margin-top: 24px;
    @include media-breakpoint-up(lg) {
      margin-top: 56px;
    }

    h1 {
      font-size: 38px;
      line-height: 1.16;
      @include media-breakpoint-up(lg) {
        font-size: 48px;
        line-height: 1.17;
      }
    }

    h1 + p {
      font-size: 18px;
      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }
    }
  }

  .postcode-hero-form-container:not(.full-width-hero-form) > .postcode-hero-panel {
    @include media-breakpoint-up(md) {
      width: 570px;
    }
  }

  .full-width-hero-form {
    @include media-breakpoint-up(md) {
      .panel {
        max-width: 610px;

        div {
          min-width: 260px;
        }
      }
    }
  }

  .form-wrapper {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 16px;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .illustration-wrapper {
    text-align: center;

    img {
      width: 420px;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 16px;
      right: -135px;
      bottom: 0;
      left: 0;
      text-align: right;
      img {
        width: 741px;
      }
    }
  }
}

.blog-overview-hero-seo {
  @include media-breakpoint-up(lg) {
    height: 470px;
  }
  margin-bottom: .5rem;

  .hero-content {
    margin-top: 16px;
    @include media-breakpoint-up(lg) {
      margin-top: 76px;
    }

    .hero-title {
      font-size: 38px;
      line-height: 44px;
      @include media-breakpoint-up(lg) {
        font-size: 48px;
        line-height: 56px;
      }
    }
  }

  .illustration-wrapper {
    text-align: center;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      right: -135px;
    }
  }
}

.blog-category-hero-seo {
  .hero-text {
    text-shadow: none;
    &.hero-title {
      color: $headings-color;
    }
  }
  .hero-description {
    > p {
      margin-bottom: 0;
    }
  }

}

.hero-directory {
  background-color: $secondary-bg;

  .hero-content {
    margin: 16px 0;
    @include media-breakpoint-up(lg) {
      margin: 48px 41px;
    }

    .postcode-hero-text {
      p {
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
      }
    }
  }

  .illustration-wrapper {
    position: relative;
    top: 0;
    padding-top: 16px;
    text-align: center;

    img {
      width: 195px;
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      right: 80px;
      left: 0;
      padding-top: 0;
      text-align: right;
      transform: translateY(-50%);
      img {
        width: 255px;
      }
    }
  }
}
