$yoast-breadcrumb-icon-right: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='#{$gray-700}' stroke-linecap='round' stroke-linejoin='round' d='M2 5.5l6 6 6-6'/%3E%3C/svg%3E"), "#", "%23") !default;
$yoast-breadcrumb-icon-width: 16px !default;
$yoast-breadcrumb-icon-height: $yoast-breadcrumb-icon-width !default;
$yoast-breadcrumb-font-family: $font-family-base !default;
$yoast-breadcrumb-separator-margin-x: 16px !default;
$yoast-breadcrumb-separator-margin-x-mobile: 8px !default;
$yoast-breadcrumb-margin-top: 16px !default;
$yoast-breadcrumb-margin-top-desktop: 24px !default;
$yoast-breadcrumb-margin-bottom: 32px !default;
$yoast-breadcrumb-margin-bottom-desktop: 56px !default;

// @deprecated implementation used in WP pages
// todo: remove after #50077 is released
/* stylelint-disable */
.yoast-breadcrumb {
  margin-top: $yoast-breadcrumb-margin-top;
  margin-bottom: $yoast-breadcrumb-margin-bottom;
  font-family: $yoast-breadcrumb-font-family;
  color: $gray-700;

  @include media-breakpoint-up(md) {
    margin-top: $yoast-breadcrumb-margin-top-desktop;
    margin-bottom: $yoast-breadcrumb-margin-bottom-desktop;
  }

  a {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    font-family: $yoast-breadcrumb-font-family;
    color: $gray-700;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  a:visited,
  a:active {
    color: $gray-700;
  }

  span a {
    margin-right: $yoast-breadcrumb-separator-margin-x;
  }

  .breadcrumb-separator ~ .breadcrumb_last {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .breadcrumb-separator ~ span a::before,
  .breadcrumb-separator ~ .breadcrumb_last::before {
    display: inline-block;
    width: $yoast-breadcrumb-icon-width;
    height: $yoast-breadcrumb-icon-height;
    margin-right: $yoast-breadcrumb-separator-margin-x;
    margin-bottom: 3px; // tightly coupled to the height of the text, otherwise it doesn't align
    vertical-align: middle;
    content: "";
    background: $yoast-breadcrumb-icon-right;
    border: none;
    transform: rotate(-90deg);
  }

  @include media-breakpoint-down(md) {
    span a {
      margin-right: $yoast-breadcrumb-separator-margin-x-mobile;
    }
    .breadcrumb-separator ~ span a::before,
    .breadcrumb-separator ~ .breadcrumb_last::before {
      margin-right: $yoast-breadcrumb-separator-margin-x-mobile;
    }
  }

  .breadcrumb_last > * {
    white-space: normal;
  }
}
/* stylelint-enable */

// actual breadcrumb implementation for both Symfony and WP pages
.breadcrumb {
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1.5rem;
  background-color: transparent;

  .breadcrumb-item {
    padding-left: 0;

    a {
      color: $gray-700;

      &:hover {
        color: $gray-700;
      }
    }

    &::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: 0;
      padding-left: .5rem;
      line-height: .75rem;
      color: $breadcrumb-divider-color;
      content: $breadcrumb-divider;
      transform: rotate(180deg);
    }

    &:first-child {
      padding-left: 0;
    }
  }

  @include media-breakpoint-up(md) {
    line-height: 1.625rem;

    .breadcrumb-item {
      padding-left: 1rem;
      &:first-child::before {
        display: none;
      }

      &::before {
        padding-right: 1rem;
        padding-left: 0;
        transform: none;
      }
    }
  }
}

